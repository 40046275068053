import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  Content,
  ImageCollection,
  Reviews,
  Contact,
} from "@bluefin/components";
import { Grid, Image } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage, getPageGalleryImages } from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={fishermanBusiness.name}
              tagline={""}
              images={getPageHeroImage({
                options: fishermanBusinessWebsitePage.components,
              })}
              ctas={[
                fishermanBusiness.ctas.phone,
                fishermanBusiness.ctas.orderOnline,
              ]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
              gutter={false}
            />
          }
          subfooter={false}
        >
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16} textAlign={"center"}>
              <Content>
                <Content.Markup>
                  {fishermanBusiness.aboutMarkdown}
                </Content.Markup>
              </Content>
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <h1>Awards</h1>
            <Grid
              className={"awards"}
              verticalAlign={"middle"}
              textAlign={"center"}
            >
              <Grid.Row>
                <Grid.Column
                  mobile={"4"}
                  tablet={"3"}
                  computer={"3"}
                  largescreen={"3"}
                >
                  <Image
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/wmbarbqburger/2011.jpg"
                    }
                    size={"small"}
                    textAlign={"center"}
                  />
                </Grid.Column>
                <Grid.Column
                  mobile={"4"}
                  tablet={"3"}
                  computer={"3"}
                  largescreen={"3"}
                >
                  <Image
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/wmbarbqburger/2012.jpg"
                    }
                    size={"small"}
                    textAlign={"center"}
                  />
                </Grid.Column>
                <Grid.Column
                  mobile={"4"}
                  tablet={"3"}
                  computer={"3"}
                  largescreen={"3"}
                >
                  <Image
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/wmbarbqburger/2013.jpg"
                    }
                    size={"small"}
                    textAlign={"center"}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column
                  mobile={"4"}
                  tablet={"3"}
                  computer={"3"}
                  largescreen={"3"}
                >
                  <Image
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/wmbarbqburger/2014.jpg"
                    }
                    size={"medium"}
                    textAlign={"center"}
                  />
                </Grid.Column>
                <Grid.Column
                  mobile={"4"}
                  tablet={"3"}
                  computer={"3"}
                  largescreen={"3"}
                >
                  <Image
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/wmbarbqburger/2015.jpg"
                    }
                    size={"medium"}
                    textAlign={"center"}
                  />
                </Grid.Column>
                <Grid.Column
                  mobile={"4"}
                  tablet={"3"}
                  computer={"3"}
                  largescreen={"3"}
                >
                  <Image
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/wmbarbqburger/2016.jpg"
                    }
                    size={"medium"}
                    textAlign={"center"}
                  />
                </Grid.Column>
                <Grid.Column
                  mobile={"4"}
                  tablet={"3"}
                  computer={"3"}
                  largescreen={"3"}
                >
                  <Image
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/wmbarbqburger/2017.jpg"
                    }
                    size={"medium"}
                    textAlign={"center"}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column
                  mobile={"4"}
                  tablet={"3"}
                  computer={"3"}
                  largescreen={"3"}
                >
                  <Image
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/wmbarbqburger/2018.jpg"
                    }
                    size={"medium"}
                    textAlign={"center"}
                  />
                </Grid.Column>
                <Grid.Column
                  mobile={"4"}
                  tablet={"3"}
                  computer={"3"}
                  largescreen={"3"}
                >
                  <Image
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/wmbarbqburger/2019.jpg"
                    }
                    size={"medium"}
                    textAlign={"center"}
                  />
                </Grid.Column>
                <Grid.Column
                  mobile={"4"}
                  tablet={"3"}
                  computer={"3"}
                  largescreen={"3"}
                >
                  <Image
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/wmbarbqburger/2020.jpg"
                    }
                    size={"medium"}
                    textAlign={"center"}
                  />
                </Grid.Column>
                <Grid.Column
                  mobile={"4"}
                  tablet={"3"}
                  computer={"3"}
                  largescreen={"3"}
                >
                  <Image
                    centered={true}
                    src={
                      "https://fisherman.gumlet.io/public/wmbarbqburger/2021.jpg"
                    }
                    size={"medium"}
                    textAlign={"center"}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <ImageCollection
                images={getPageGalleryImages({
                  options: fishermanBusinessWebsitePage.components,
                })}
                as={"gallery"}
                centered={true}
                cutUnevenRows={false}
                disable={"none"}
                header={"Photos"}
                numberOfRowDisplayItems={3}
              />
            </Grid.Column>
          </Grid>
          <Grid stackable={true} className={"component-section-container"}>
            <Grid.Column width={16}>
              <Reviews
                reviews={fishermanBusiness.reviews}
                as={"horizontalItem"}
                buttonBasic={false}
                buttonSize={"medium"}
                centerHeader={true}
                cardInlinePhoto={"left"}
                header={"Reviews"}
                imageCircular={false}
                imageSize={"small"}
                itemButtonFloat={false}
                itemCenterContent={false}
                itemsDivided={false}
                itemsPerRow={5}
                linkAs={"none"}
                onlyEvenRows={true}
                showImage={true}
                verticalItemAlign={"left"}
              />
            </Grid.Column>
          </Grid>
          <Grid stackable={true} className={"component-section-container"}>
            <Grid.Column width={16}>
              <Contact
                businessName={fishermanBusiness.name}
                phoneNumber={fishermanBusiness.primaryLocation.phoneNumber}
                hours={fishermanBusiness.primaryLocation.hours}
                address={{
                  street: fishermanBusiness.primaryLocation.street,
                  city: fishermanBusiness.primaryLocation.city,
                  state: fishermanBusiness.primaryLocation.state,
                  zip: fishermanBusiness.primaryLocation.zipCode,
                }}
                as={"text"}
                buttonColor={"secondary"}
                header={"Contact"}
                image={""}
                showMap={true}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      ctas {
        phone {
          text
          link
          internal
        }
        orderOnline {
          text
          link
          internal
          className
        }
      }
      aboutMarkdown
      reviews {
        author
        text
        link
      }
      primaryLocation {
        phoneNumber
        hours {
          open
          close
          label
          day
        }
        street
        city
        state
        zipCode
      }
    }
    fishermanBusinessWebsitePage(title: { eq: "Home" }) {
      title
      description
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
